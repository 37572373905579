<template>
  <div class="page-cont">
    <!-- <el-carousel :interval="5000" arrow="always" height="634px">
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <img :src="item.img" alt="" />
        <span class="text">{{ item.text }}</span>
      </el-carousel-item>
    </el-carousel> -->
    <Carousel type="Frenk"></Carousel>

    <div class="us">
      <div class="os">
        <div class="titleus">
          <div class="top">
            <img src="~@/assets/img/fr-title1.png" alt="">
            <span class="name">为什么选择我们</span>
            <img src="~@/assets/img/fr-title2.png" alt="">
          </div>
          <div class="bon">Why choose us</div>
        </div>

        <div class="ls">
          <div class="list">
            <div class="cont">
              <div class="img"><img src="~@/assets/img/fr-pro1.png" alt=""></div>
              <div class="name1">优质服务</div>
              <div class="name2">High quality service</div>
            </div>
          </div>
          <div class="list">
            <div class="cont">
              <div class="img"><img src="~@/assets/img/fr-pro2.png" alt=""></div>
              <div class="name1">种类多样</div>
              <div class="name2">Diverse types</div>
            </div>
          </div>
          <div class="list">
            <div class="cont">
              <div class="img"><img src="~@/assets/img/fr-pro3.png" alt=""></div>
              <div class="name1">诚信经营</div>
              <div class="name2">Integrity management</div>
            </div>
          </div>
          <div class="list">
            <div class="cont">
              <div class="img"><img src="~@/assets/img/fr-pro4.png" alt=""></div>
              <div class="name1">发货及时</div>
              <div class="name2">Timely delivery</div>
            </div>
          </div>
          <div class="list">
            <div class="cont">
              <div class="img"><img src="~@/assets/img/fr-pro5.png" alt=""></div>
              <div class="name1">生产厂家</div>
              <div class="name2">Manufacturer</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="us pro">
      <div class="os">
        <div class="titleus">
          <div class="top">
            <img src="~@/assets/img/fr-title1.png" alt="">
            <span class="name">产品分类</span>
            <img src="~@/assets/img/fr-title2.png" alt="">
          </div>
          <div class="bon">Product classification</div>
        </div>
        <div class="cp">
          <div class="list we-provide" @click="tegoryFuc(item)" v-for="(item,index) in tegoryList" :key="index">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg :width="width" :height="width" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fb5e3c" />
							</svg>
            </div>
            <div class="name">{{item.cateName}}</div>
          </div>
          <!-- <div class="list we-provide">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg width="188" height="188" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fecc17" />
							</svg>
            </div>
            <div class="name">产品名</div>
          </div>
          <div class="list we-provide">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg width="188" height="188" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fecc17" />
							</svg>
            </div>
            <div class="name">产品名</div>
          </div>
          <div class="list we-provide">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg width="188" height="188" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fb5e3c" />
							</svg>
            </div>
            <div class="name">产品名</div>
          </div>
          <div class="list we-provide">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg width="188" height="188" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fecc17" />
							</svg>
            </div>
            <div class="name">产品名</div>
          </div>
          <div class="list we-provide">
            <div class="cont we-provide-img">
              <img src="~@/assets/img/pro2.png" alt="">
              <svg width="188" height="188" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M9.82698 416.603C-19.0352 298.701 18.5108 173.372 107.497 90.7633L110.607 96.5197C24.3117 177.199 -12.311 298.935 15.0502 413.781L9.82698 416.603ZM89.893 565.433C172.674 654.828 298.511 692.463 416.766 663.224L414.077 658.245C298.613 686.363 175.954 649.666 94.9055 562.725L89.893 565.433ZM656.842 259.141C685.039 374.21 648.825 496.492 562.625 577.656L565.413 582.817C654.501 499.935 691.9 374.187 662.536 256.065L656.842 259.141ZM581.945 107.518C499.236 18.8371 373.997 -18.4724 256.228 10.5134L259.436 16.4515C373.888 -10.991 495.248 25.1518 576.04 110.708L581.945 107.518Z"
									fill="#fb5e3c" />
							</svg>
            </div>
            <div class="name">产品名</div>
          </div> -->
        </div>
      </div>
    </div>


    <div class="us">
      <div class="os">
        <div class="puct" :class="popularList.length < 3?'puct2':''">
          <div class="left">
            <div v-if="popularList.length > 0" class="list list1" @click="isYhFuc(popularList[0].id)">
              <img class="img" :src="imgUrl+popularList[0].thumbnail" alt="">
              <div class="puct-text">
                <div class="name1 nowrap">{{popularList[0].name}}</div>
                <div class="name2 nowrap">{{popularList[0].remarks}}</div>
              </div>
            </div>
            <!-- <div class="name1">{{item.name}}</div>
              <div class="name2">{{item.remarks}}</div>
              <div class="name3" @click="isYhFuc(item)">了解更多</div> -->
            <div v-if="popularList.length > 1" class="list" @click="isYhFuc(popularList[1].id)">
              <img class="img" :src="imgUrl+popularList[1].thumbnail" alt="">
              <div class="puct-text">
                <div class="name1 nowrap">{{popularList[1].name}}</div>
                <div class="name2 nowrap">{{popularList[1].remarks}}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="list" v-if="popularList.length > 2" @click="isYhFuc(popularList[2].id)">
              <img class="img" :src="imgUrl+popularList[2].thumbnail" alt="">
              <div class="puct-text">
                <div class="name1 nowrap">{{popularList[2].name}}</div>
                <div class="name2 nowrap">{{popularList[2].remarks}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="us pro">
      <div class="ps">
        <div class="titleus">
          <div class="top">
            <img src="~@/assets/img/fr-title1.png" alt="">
            <span class="name">热门产品</span>
            <img src="~@/assets/img/fr-title2.png" alt="">
          </div>
          <div class="bon">Product classification</div>
        </div>
        
        <div class="cont-box">
          <div class="container">
            <ProductList type="frenkHot"></ProductList>
            <!-- <div class="row plush">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot" @click="plush">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
              </el-row>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="us yhcp">
      <div class="os">
        <div class="titleus">
          <div class="top">
            <img src="~@/assets/img/fr-title1.png" alt="">
            <span class="name">优惠产品</span>
            <img src="~@/assets/img/fr-title2.png" alt="">
          </div>
          <div class="bon">Product classification</div>
        </div>
        <div class="cp">
          <div class="list" v-for="(item,index) in isYhList" :key="index">
            <div class="cont"><img :src="imgUrl+item.thumbnail" alt=""></div>
            <div class="text-box">
              <div class="name1">{{item.name}}</div>
              <div class="name2">{{item.remarks}}</div>
              <div class="name3" @click="isYhFuc(item)">了解更多</div>
            </div>
          </div>
          <!-- <div class="list">
            <div class="cont"><img src="~@/assets/img/yhcp.png" alt=""></div>
            <div class="text-box">
              <div class="name1">产品名称产品名称</div>
              <div class="name2">产品介绍产品介绍产品介绍产产品介绍产产品介绍产…</div>
              <div class="name3">了解更多</div>
            </div>
          </div>
          <div class="list">
            <div class="cont"><img src="~@/assets/img/yhcp.png" alt=""></div>
            <div class="text-box">
              <div class="name1">产品名称产品名称</div>
              <div class="name2">产品介绍产品介绍产品介绍产产品介绍产产品介绍产…</div>
              <div class="name3">了解更多</div>
            </div>
          </div> -->
          <!-- <div class="list">
            <div class="cont"><img src="~@/assets/img/yhcp.png" alt=""></div>
            <div class="text-box">
              <div class="name1">产品名称产品名称</div>
              <div class="name2">产品介绍产品介绍产品介绍产产品介绍产产品介绍产…</div>
              <div class="name3">了解更多</div>
            </div>
          </div> -->
        </div>
        <div class="frk">
          <div class="title-frk">富瑞可freak</div>
          <div class="details">
            苏州天瑞宠物用品有限公司，成立于2016年，位于江苏省苏州市，是一家以从事制造业为主的企业。企业注册资本200万人民币。通过天眼查大数据分析，苏州天瑞宠物用品有限公司商标信息4条；此外企业还拥有行政许可1个。苏州天瑞宠物用品有限公司，成立于2016年，位于江苏省苏州市，是一家以从事制造业为主的企业。企业注册资本200万人民币。通过天眼查大数据分析，苏州天瑞宠物用品有限公司商标信息4条；此外企业还拥有行政许可1个。苏州天瑞宠物用品有限公司，成立于2016年，位于江苏省苏州市，是一家以从事制造业为主的企业。
          </div>
        </div>
      </div>
    </div>


    <div class="us pro">
      <div class="ps">
        <div class="titleus">
          <div class="top">
            <img src="~@/assets/img/fr-title1.png" alt="">
            <span class="name">全部产品</span>
            <img src="~@/assets/img/fr-title2.png" alt="">
          </div>
          <div class="bon">Product classification</div>
        </div>
        
        <div class="cont-box">
          <div class="container">
            <!-- <div class="row plush">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot" @click="plush">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="plush-box">
                    <div class="plush-top">
                      <img src="~@/assets/img/xiong.png" alt="" />
                    </div>
                    <div class="plush-bot">Plush Series</div>
                  </div>
                </el-col>
              </el-row>
            </div> -->
            <ProductList type="frenkAll"></ProductList>
          </div>
        </div>
        <!-- <el-pagination
          background
          :current-page="current"
          :page-sizes="[10, 20, 30]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: center;margin-top: 15px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        /> -->
      </div>
    </div>

  </div>
</template>
<script>
import { getAction } from "@/api/manage";
export default {
  name: "index",

  data() {
    return {
      // carouselList: [
      //   {
      //     img: require("@/assets/img/xiaomi.png"),
      //     text: "See you at FREAKOUSE",
      //   },
      //   // {
      //   //   img: require('@/assets/img/lunbo.png'),
      //   //   text: 'See you at FREAKOUSE'
      //   // },
      // ],
      total: 0,
      current: 1,
      size: 10,
      tegoryList: [],
      isYhList: [],
      imgUrl: process.env.VUE_APP_BASE_IMG,
      popularList: [],

      width: 188
    }
  },
  mounted() {

    let devw = window.screen.width;
    if(devw > 768) {
      this.width = 188
    } else {
      this.width = 150
    }
    // 产品分类
    this.tstoreCategory()
    // 喜爱的
    this.isPopular()
    // 优惠产品
    this.isYh()

  },
  methods: {
    isPopular() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tproductInfo/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          isPopular: 1
        }
      ).then((res) => {
        // console.log(res);
        let list = res.data.list
        if (list.length > 3) {
          this.popularList = list.slice(0,3)
        }else {
          this.popularList = list
        }
      });
    },
    isYh() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tproductInfo/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          isYh: 1
        }
      ).then((res) => {
        // console.log(res);
        let list = res.data.list
        if (list.length > 3) {
          this.isYhList = list.slice(0,4)
        }else {
          this.isYhList = list
        }
      });
    },
    isYhFuc(item) {
      this.$router.push({
        path: "/ProductDeta",
        // query: { item: JSON.stringify(item) },
        query: { id: item.id },
      });
    },
    tstoreCategory() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tstoreCategory/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang')
        }
      ).then((res) => {
        let list = res.data.list
        if (list.length > 6) {
          this.tegoryList = list.slice(0,6)
        }else {
          this.tegoryList = list
        }
      });
    },
    // 产品跳转
    tegoryFuc(item) {
      this.$router.push({
        path: "/Products",
        // query: { item: JSON.stringify(item) },
        query: { id: item.id },
      });
    },
    plush() {
      this.$router.push({
        path: "/ProductDeta",
        // query: { item: this.search },
      });
    },
    // 分页方法
    handleSizeChange(val) {
      this.size = val
      // this.loadData()
    },
    // 分页方法
    handleCurrentChange(val) {
      this.current = val
      // this.loadData()
    },

  },
}
</script>

<style lang="scss" scoped>
.we-provide img{
  margin-bottom: 0;
}
.we-provide-img svg{
  top: 50%;
}
.page-cont {
  .text {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    top: 31%;
    color: #fff;
    font-size: 80px;
    font-weight: bold;
  }
  .titleus{
    text-align: center;
    margin: 20px 0 10px;
    .name{
      font-size: 30px;
      margin: 0px 20px;
    }
    .bon{
      color: #707070;
      font-size: 22px;
      font-family: 'DynaPuff';
    }
  }
  .os{
    width: 1249px;
    margin: 0 auto;
    padding: 10px 0;
    .ls{
      display: flex;
      justify-content: space-between;
      .cont{
        width: 244px;
        height: 273px;
        text-align: center;
        background: url("~@/assets/img/fr-pro.png");
        background-size: 100% 100%;
        .img{
          padding: 49px 0 10px;
        }
        .name1{
          font-size: 26px;
          cursor: default;
          transition: .4s ease-in-out;
        }
        .name2{
          font-size: 18px;
          color: #707070;
          cursor: default;
          transition: .4s ease-in-out;
        }
      }
    }
    .cont:hover .name1{
      color: #fa441d;
    }
    .cont:hover .name2{
      color: #c96161;
    }
  }

  

  .pro{
    background: #F3F2ED;
    padding: 20px 0;
    .cp{
      display: flex;
      // justify-content: space-between;
      .list{
        cursor: pointer;
        text-align: center;
        width: 20%;
      }
      .name{
        cursor: default;
        font-size: 33px;
        color: #707070;
        margin-top: 5px;
        transition: .4s ease-in-out;
      }
      .list:hover .name{
        color: #fa441d;
      }
      .cont{
        width: 190px;
        height: 190px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img{
          width: 160px;
          height: 160px;
          border-radius: 50%;
        }
      }
    }
  }

  .puct{
    display: flex;
    .left{
      width: 30%;
      .list1{
        margin-bottom: 12px;
      }
      img{
        width: 385px;
        height: 347px;
      }
    }
    .right{
      width: 67%;
      margin-left: 3%;
      img{
        width: 802px;
        height: 712px;
      }
    }
    .list{
      position: relative;
      color: #fff;
      .puct-text{
        position: absolute;
        bottom: 16px;
        left: 20px;
        .name1{
          font-size: 20px;
          width: 100%;
        }
        .name2{
          font-size: 12px;
          width: 100%;
        }
      }
    }
  }
  .puct2{
    .left{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .right{
      display: none;
    }
  }

  .ps{
    width: 1400px;
    margin: 0 auto;
  }
  .cont-box {
    background-size: 100% 100%;
    // padding: 80px 0;
    // min-height: 800px;
    .container{
      width: 1250px;
      margin: 0 auto;
    }
  }
  // .plush{
  //   margin-top: 30px;
  // }
  // .plush-box {
  //   text-align: center;
  //   margin-bottom: 20px;
  //   .plush-top {
  //     width: 280px;
  //     height: 280px;
  //     background-color: #fff;
  //     border-radius: 89px 10px 10px 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     img{
  //       width: 140px;
  //     }
  //   }
  //   .plush-bot {
  //     cursor: pointer;
  //     width: 280px;
  //     height: 60px;
  //     line-height: 60px;
  //     background-color: #e6e5e1;
  //     color: #9d856b;
  //     font-size: 22px;
  //     font-weight: bold;
  //     margin-top: 8px;
  //     border-radius: 10px;
  //   }
  // }

  .yhcp{
    .cp{
      width: 1249px;
      display: flex;
      .list{
        width: 25%;
        border: 1px solid #EBEBEB;
        margin-right: 10px;
        border-radius: 5px;
        overflow: hidden;
        .text-box{
          // border: 1px solid #EBEBEB;
          border-top: none;
          padding: 10px 15px;
          width: 268px;
        }
        .name1{
          font-size: 15px;
          font-weight: bold;
        }
        .name2{
          font-size: 12px;
        }
        .name3{
          cursor: pointer;
          width: 85px;
          height: 26px;
          line-height: 26px;
          font-size: 12px;
          text-align: center;
          border-radius: 43px;
          background: #CDBA93;
          color: #fff;
          margin-top: 10px;
          transition: all 0.5s;
        }
        .name3:hover{
          background: #a18f6b;
        }
        .cont{
           height: 270px;
           overflow: hidden;
           img{
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }
        }
        .cont:hover img{
          transform: scale(1.05);
        }
        
      }
      .list:last-child{
        margin-right: 0;
      }
    }
    .frk{
      margin-top: 15px;
      width: 1249px;
      height: 363px;
      background: url("~@/assets/img/frk-bg.png");
      background-size: 100% 100%;
      color: #fff;
      .title-frk{
        font-size: 37px;
        text-align: center;
        height: 100px;
        line-height: 110px;
      }
      .details{
        font-size: 20px;
        line-height: 36px;
        width: 80%;
        margin: 0 auto;
        text-align: justify;
        height: 200px;
        overflow: auto;
      }
    }
  }

}

.nowrap{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

@media (max-width: 768px){
  .page-cont .os{
    width: initial;
    padding: 1px 0;
  }
  .page-cont .os .ls{
    display: block;
  }
  .page-cont .os .ls .list{
    display: inline-block;
    width: 50%;
  }
  .page-cont .os .ls .cont{
    width: initial;
  }
  .page-cont .titleus .name{
    font-size: 20px;
  }
  .page-cont .titleus .bon{
    font-size: 18px;
  }
  .page-cont .os .ls .cont .name1{
    font-size: 16px;
  }
  .page-cont .os .ls .cont .name2{
    font-size: 14px;
  }
  .page-cont .os .ls .cont .img{
    padding: 26px 0 10px;
  }
  .page-cont .os .ls .cont{
    height: 231px;
  }

  .page-cont .pro .cp{
    display: block;
  }
  .page-cont .pro .cp .list{
    width: 50%;
    display: inline-block;
    margin-bottom: 10px;
  }
  .page-cont .pro .cp .cont{
    width: 150px;
    height: 150px;
  }
  .page-cont .pro .cp .cont img{
        width: 130px;
    height: 130px;
  }
  .page-cont .pro .cp .name{
    font-size: 16px;
  }

  .page-cont .puct2 .left{
    display: block;
  }

  .page-cont .ps{
    width: initial;
  }

  .page-cont .yhcp .frk{
    width: initial;
    height: initial;
  }
  .page-cont .yhcp .frk .title-frk{
    font-size: 20px;
    height: initial;
    line-height: 50px;
  }
  .page-cont .yhcp .frk .details{
    font-size: 14px;
    line-height: 30px;
    width: 90%;
    height: initial;
    overflow: initial;
  }

}
</style>
<style scoped>
.page-cont >>> .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #CDBA93;
}
.page-cont >>> .el-select-dropdown__item.selected,.page-cont >>> .el-select-dropdown__item.selected{
  color: #CDBA93 !important;
}

</style>

<style scoped>
.plush >>> .el-col-6{
  display: flex;
  justify-content: center;
}
/deep/ .el-select-dropdown__item.selected{
    color: #CDBA93 ;
}


</style>
